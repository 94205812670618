import React from 'react'

import * as styles from './tags.module.scss'

const Tags = ({ tags }: { tags: (string | null)[] }) => {
  if (tags?.length === 0) {
    return null
  }
  return (
    <small className={styles.tags}>
      {tags.map((tag) => (
        <div key={tag} className={styles.tag}>
          {tag}
        </div>
      ))}
    </small>
  )
}
export default Tags
