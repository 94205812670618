import React from 'react';
import { graphql, PageProps } from 'gatsby';

import Seo from '../components/seo';
import Layout from '../components/layout';
import Hero from '../components/hero';
import ArticlePreview from '../components/article-preview';
import { Query } from '../../gatsby-graphql';
import Section from '../components/section';
import Container from '../components/container';

interface Props {
  allContentfulBlogPost: Query['allContentfulBlogPost'];
}

export const BlogPage = ({ location, data }: PageProps<Props>) => {
  const posts = data.allContentfulBlogPost.nodes;
  return (
    <Layout location={location}>
      <Seo title="Blog" />
      <Section fixedWidth>
        <Container>
          <ArticlePreview posts={posts} />
        </Container>
      </Section>
    </Layout>
  );
};

export default BlogPage;

export const pageQuery = graphql`
  query BlogIndexQuery {
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
      nodes {
        title
        slug
        publishDate(formatString: "MMMM Do, YYYY")
        editedDate(formatString: "MMMM Do, YYYY")
        tags
        heroImage {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 424, height: 212)
        }
        # description {
        #   raw
        # }
      }
    }
  }
`;
