import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { renderRichText } from 'gatsby-source-contentful/rich-text';

import Container from './container';
import Tags from './tags';
import * as styles from './article-preview.module.scss';
import Column from './column';
import Row from './row';

interface Props {
  posts: any;
  fixedWidth?: boolean;
}

const ArticlePreview = ({ posts, fixedWidth = false }: Props) => {
  if (!posts) return null;
  if (!Array.isArray(posts)) return null;

  return (
    <ul className={`${styles.articleList} ${fixedWidth && styles.fixedWidth}`}>
      {posts.map((post) => {
        return (
          <li key={post.slug}>
            <Link to={`/blog/${post.slug}`} className={styles.link}>
              <GatsbyImage alt="" objectFit="contain" objectPosition="center" image={post?.heroImage?.gatsbyImageData} />
              <h2 className={styles.title}>{post.title}</h2>
            </Link>
            <div>{post.description?.raw && renderRichText(post.description)}</div>
            <div className={styles.meta}>
              <small className="meta">{post.editedDate ?? post.publishDate}</small>
              {post.tags && <Tags tags={post?.tags} />}
            </div>
          </li>
        );
      })}
    </ul>
  );
};

export default ArticlePreview;
